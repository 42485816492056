<template>
  <div v-if="services.length" class="service-item-content__projects">
    <h2 class="projects-tabs__title">Другие услуги</h2>
    <div class="service-item-content__projects-blocks">
      <ServicePageCardComponent v-for="(item, i) in services" :key="i" :data="item" />
    </div>
  </div>
</template>

<script>
import ServicePageCardComponent from "./ServicePageCardComponent.vue";

export default {
  name: "ServiceServicesCardsComponent",
  components: { ServicePageCardComponent },
  computed: {
    services() {
      return this.$store.state.service_page.services.filter((item) => !item.is_main);
    },
  },
};
</script>

<style lang="stylus">
.tab-component {
  display: flex;
  flex-direction: column;

  &__btns {
    padding: 32px 100px 0;
    display: flex;
    gap: 20px;

    +below(1400px) {
      padding: 32px 80px 0;
      display: flex;
      flex-wrap: wrap;
    }

    +below(1100px) {
      padding: 16px 15px 0;
    }

    .btn {
      height: 40px;
    }

    &-active {
      color: var(--gray);
      background: var(--blue);
    }
  }

  &__services {
    padding: 60px 100px;
    background-color: var(--white);
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px

    +below(1400px) {
      padding: 60px 80px 32px 80px;
    }

    +below(1100px) {
      padding: 30px 15px 32px 15px;
    }

    +below(1024px) {
      grid-template-columns repeat(2, 1fr)
    }

    +below(728px) {
      grid-template-columns repeat(1, 1fr)
    }

    & .service-card {
      background var(--gray-1)
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    transition: var(--transition);

    //.slider__container {
    //  padding 32px 100px
    //}

    .slider__title {
      padding-bottom 32px
    }

    &-direction {
      padding: 60px 100px;
      background-color: var(--gray-1);

      +below(1400px) {
        padding: 60px 80px 0 80px;
      }

      +below(1100px) {
        padding: 30px 15px 0 15px;
      }

      &_title {
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 35px;
        color: var(--blue-o5);

        +below(1100px) {
          font-size: 1.25rem;
        }
      }

      &_blocks {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding-top: 60px;
        gap: 60px;
        align-items: end;

        +below(1100px) {
          padding: 30px 15px 0 15px;
          grid-template-columns: 1fr;
        }

        button {
          max-width: 220px;
          +below(1100px) {
            max-width 100%
          }
        }
      }
    }

    & .our-destinations {
      background var(--white)
      padding-top: 30px

      & .our-destinations__title {
        color var(--blue-o5)
      }
    }

    &-projects {
      padding: 32px 100px;

      +below(1400px) {
        padding: 32px 80px 0;
      }

      +below(1100px) {
        padding: 16px 15px 30px;
      }

      h2 {
        font-weight: 500;
        font-size: 1.875rem;
        line-height: 35px;
        color: var(--blue-o5);

        +below(1100px) {
          font-size: 1.25rem;
        }
      }

      &_blocks {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-top: 32px;
        grid-gap: 60px;

        +below(1800px) {
          grid-template-columns: 1fr 1fr;
        }

        +below(1400px) {
          grid-gap: 32px;
        }

        +below(1100px) {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
</style>
