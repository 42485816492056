<template>
  <router-link :to="{ name: 'service-item', params: { slug: data.slug, service_id: data.id } }" class="project-card">
    <ImageComponent
      :head_img="image"
      class="project-card__image"
      width="700"
      height="500"
      :title="data.title"
    />
    <div class="project-card__content">
      <span class="project-card__title">{{ data.title }}</span>
    </div>
  </router-link>
</template>

<script>
import ImageComponent from "components/ImageComponent.vue";

export default {
  name: "ServicePageCardComponent",
  components: { ImageComponent },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      windowWidth: typeof window !== "undefined" ? window.innerWidth : 0
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  computed: {
    image() {
      return this.windowWidth > 560 ? this.data.map_image : this.data.mob_image
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    }
  }
};
</script>
