<template>
  <main class="service-page">
    <article
      class="service-page-description"
      :style="{
        'background-image': data.img ? `url('${$store.state._env.MEDIA_ENDPOINT + data.img.path}')` : '',
        'background-color': data.color_banner,
      }"
    >
      <BreadcrumbsComponent :links="breadcrumbs" />
      <h2 class="service-page-description__title" v-if="data.title">{{ data.title }}</h2>
      <h1 style="display: none" v-if="data.title">{{ data.title }}</h1>
      <span class="service-page-description__subtitle" v-if="data.short_description">{{ data.short_description }}</span>
    </article>
    <ServiceItemContentComponent />
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import GET from "@/graphql/queries/service_item_page_by_slug.graphql";
import ServiceItemContentComponent from "./components/ServiceItemContentComponent.vue";

export default {
  name: "ServiceItemPage",
  components: { ServiceItemContentComponent, BreadcrumbsComponent },
  async asyncData({ apollo, store, route, res }) {
    let slug = route.params.slug;
    if (!slug) {
      res.status(404);
      return;
    }
    await apollo.defaultClient
      .query({
        query: GET,
        variables: { slug, service_id: route.params.id },
      })
      .then(({ data }) => {
        if (data && data.services_item_by_slug) {
          store.state.services_item_page.service_item = data.services_item_by_slug;
          store.state.services_item_page.projects = data.projects;
          store.state.service_page.services = store.state.services_item_page.service_item.services
        } else {
          res.status(404);
        }
      })
      .catch(() => {});
  },
  data() {
    return {};
  },
  computed: {
    data() {
      return this.$store.state.services_item_page.service_item;
    },
    breadcrumbs() {
      return [
        {
          route: {
            name: "services",
            path: "services",
          },
          title: "Услуги",
        },
        {
          route: {
            name: 'service-item',
            path: 'service',
            params: this.$route.params,
          },
          title: this.data.title,
        },
      ];
    },
  },
  metaInfo() {
		if (!this.data) {
			return {};
		}

		const itemListElement = this.breadcrumbs.map((crumb, index) => ({
			"@type": "ListItem",
			"position": index + 2,
			"item": {
				"@id": "https://comrades.dev/" + crumb.route.path + (crumb.route.params?.slug ? '/' + crumb.route.params?.slug : ''),
				"name": crumb.title
			}
		}));

		itemListElement.unshift({
			"itemListElement": {
				"@type": "ListItem",
				"position": 1,
				"item": {
					"@id": "https://comrades.dev",
					"name": "Главная"
				}
			}
		})

		const jsonLd = {
			"@context": "https://schema.org",
			"@type": "BreadcrumbList",
			"itemListElement": itemListElement
		};

    return {
      title: this.$store.state.services_item_page.service_item.meta_title,
      meta: [
        {
          vmid: "keywords",
          name: "keywords",
          content: this.$store.state.services_item_page.service_item.meta_keywords,
        },
        {
          vmid: "description",
          name: "description",
          content: this.$store.state.services_item_page.service_item.meta_description,
        },
        {
          vmid: "og:title",
          name: "og:title",
          content: this.$store.state.services_item_page.service_item.meta_title,
        },
        {
          vmid: "og:description",
          name: "og:description",
          content: this.$store.state.services_item_page.service_item.meta_description,
        }
      ],
			script: [{
				type: 'application/ld+json',
				json: jsonLd
			}]
    }
  },
};
</script>

<style lang="stylus">
.service-page {
  display flex
  flex-direction column
  gap 60px
  padding 0 100px
  margin-bottom 60px
  width 100%
  +below(1400px) {
    padding 0 80px
  }
  +below(1100px) {
    padding 0 15px
  }
}


.service-page-description {
  display flex
  flex-direction column
  //background-color var(--gray-5-o5)
  background-position right center
  background-repeat no-repeat
  background-size 50%
  margin 0 -100px
  padding 32px 100px 60px
  +below(1400px) {
    margin 0 -80px
    padding: 32px 80px 60px;
  }
  +below(1100px) {
    margin 0 -15px
    padding: 32px 15px;
  }
  +below(900px) {
    background-image none !important
  }

  .breadcrumbs {
    &__img .icon path {
      fill var(--white)
    }

    ul li span,
    ul li a {
      color var(--white)
    }
  }

  &__title {
    font-weight: 500;
    font-size: 3.75rem;
    line-height: 71px;
    color: var(--white);
    padding 60px 0 8px
    max-width 50%
    +below(900px) {
      max-width 100%
    }
    +below(530px) {
      font-size 3rem
      line-height 45px
    }
    +below(430px) {
        font-size 2.5rem
    }
  }

  &__subtitle {
    font-weight: 400;
    width: 100%;
    font-size: 1.25rem;
    line-height: 24px;
    color: var(--white);
    padding-top: 15px;
    white-space pre-line

    +below(1100px) {
      font-size: 1rem;
      width: 100%;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 1.25rem;
    line-height: 24px;
    color: var(--white);
    max-width 50%
    width 100%
    padding-top 32px

    +below(900px) {
      max-width 100%
    }

    +below(700px) {
      font-size: 1rem
      line-height: 19px;
      padding-top: 16px
    }
  }
}
</style>
